import Headers from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import styles from "./layout.module.css"
import Slider from "./Maincontentcomponents/Slider.js"
import Row from "./Maincontentcomponents/Row"
// import Rowcard from "./Maincontentcomponents/Rowcard"
// import Word from "./Maincontentcomponents/word"
import Rowparagraph from "./Maincontentcomponents/Rowparagraph"
import Parallaxrow from "./Maincontentcomponents/Parallaxrow"
import Parallaxcard from "./Maincontentcomponents/Parallaxcard"
import Parallaxword from "./Maincontentcomponents/Parallaxword"
import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'font-awesome/css/font-awesome.min.css';
import Square from "./Headercomponents/square"

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react"
import Animation1 from "./Maincontentcomponents/Animation1"
import Animation2 from "./Maincontentcomponents/Animation2"
import Animation3 from "./Maincontentcomponents/Animation3"
import HeaderAnimation from "./Maincontentcomponents/HeaderAnimation"
import Logos from "../resources/images/mog-long.png"
import Electricity from "./Headercomponents/electricity"
import { useNavigate } from "react-router-dom"
import { useRef } from "react"

// import voteImg from '../resources/images/vote.png'
import registerImg from '../resources/images/registration-icon.png'
import nominateImg from '../resources/images/nominate.png'
import loginImg from '../resources/images/login.png'
import voteImg from '../resources/images/vote-icon.png'
import payImg from '../resources/images/pay-15.png'
import voteVideo from '../resources/videos/voteVideo.mp4'


import someonePaying from '../resources/images/pay2.jpg'
import someoneVoting from '../resources/images/vote2.jpg';
import someoneRegistering from '../resources/images/register3.jpg'
import Parallaxword2 from "./Maincontentcomponents/Parallaxword2.js";
import Countdown from 'react-countdown';
import Parallaxword3 from "./Maincontentcomponents/Parallaxword3.js";
import nurse1 from '../resources/images/nurse2.jpg'
import nurse2 from '../resources/images/nurse3.webp'
import nurse3 from '../resources/images/nurse4.jpg'
import nurse4 from '../resources/images/nurse1.png'

import moh from '../resources/images/moh.png'
import famanova from '../resources/images/famanova.png'
import jhpiego from '../resources/images/jhpiego.jpg'
import hollardinsurance from '../resources/images/hollardinsurance.jpeg'
import nursingmidwifery from '../resources/images/nursemidwifery.jpg'
export default function Layout() {

    const service = useRef(null);
    const address = useRef(null);

    const ourService = () => {

        service.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const ourAddress = () => {

        address.current?.scrollIntoView({ behavior: 'smooth' });
    };


    //header

    const [navbar, setNavbar] = useState(false)
    const modifyHeader = () => {
        // console.log(window.scrollY);
        if (window.scrollY < 80) {
            setNavbar(true);
        }
        else {
            setNavbar(false);
        }
    }

    window.addEventListener("scroll", modifyHeader)
    const [display, setDisplay] = useState('none')

    function handleClick() {

        if (display === 'none') {

            setDisplay('block')

        } else {

            setDisplay('none')

        }

    }


    const navigate = useNavigate();


    function Apply() {
        navigate('/new-application');
    }
    const boxVariant = {
        visible: { opacity: 1, y: 0, transition: { duration: 2 } },
        hidden: { opacity: 0.2, y: 20 }
    };
    const control = useAnimation();
    const [ref, inView] = useInView();
    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);


    const afterFeedback = <div className={styles.afterfeedbackTab} >
        <div>Thanks!!</div>

    </div>

    const feedback = <form className={styles.feedbackTab} onSubmit={handleSubmit}>

        <div className={styles.rating}>
            <div className={styles.rateHeading}>  RATE US</div>

            <div className={styles.rateContent}>
                <div className={styles.rate}>
                    <input type="radio" id="star5" name="rate" value="5" />
                    <label for="star5" title="text">5 stars</label>
                    <input type="radio" id="star4" name="rate" value="4" />
                    <label for="star4" title="text">4 stars</label>
                    <input type="radio" id="star3" name="rate" value="3" />
                    <label for="star3" title="text">3 stars</label>
                    <input type="radio" id="star2" name="rate" value="2" />
                    <label for="star2" title="text">2 stars</label>
                    <input type="radio" id="star1" name="rate" value="1" />
                    <label for="star1" title="text">1 star</label>
                </div>
            </div>
            <div className={styles.rateCaptions}>
                <i className="fa fa-hand-o-up fa-flip "></i>
                Rate Us
            </div>
        </div>

        <div className={styles.comments}>

            <div className={styles.commentTitle}>Kindly leave your feedback</div>
            <div>
                <div className={styles.commentForm}>
                    <textarea required name="comments">

                    </textarea>
                    <button className={styles.applicationButton}>Submit</button>
                </div>
            </div>
        </div>
    </form>

    const [feedbackTab, setfeedbackTab] = useState(feedback);



    async function handleSubmit(event) {
        event.preventDefault();

        const data = new FormData(event.target);

        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: data
        };
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}add-comment`, requestOptions);

        const datas = await response.json();

        if (response.ok) {
            setfeedbackTab(afterFeedback)
        }
        else {
            alert('application not sent. please try sending the application again')
        }
    }
    const Completionist = () => <span>Event has Started!</span>;

    // Renderer callback with condition
    const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            // Render a countdown
            return <span>{days} days: {hours} hrs : {minutes} mins : {seconds} secs </span>;
        }
    };

    return (
        <div className={styles.layout}>
            <>  <div className={styles.headerTop}>
                <div class={styles.headerTopleft}>
                    <span>5th Anual Nursing and Midwifery Leaders And Managers Conference and 4th Excellence Awards</span>
                </div>

                <div class={styles.headerTopright}>
                    <span><i class="fa fa-envelope" aria-hidden="true"></i>&nbsp; <a href="mailto:info@nmc.gov.gh"> info@nmc.gov.gh</a></span>
                    <span><i class="fa fa-phone" aria-hidden="true"></i>&nbsp; <a href="tel:233302522909"> +233 302 522 909</a></span>


                    <span className={styles.headertop_fa}>
                        <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>&nbsp;&nbsp;
                        <a href="#" class="twitter"><i class="fa fa-twitter"></i></a>&nbsp;&nbsp;
                        <a href="#" class="google"><i class="fa fa-instagram"></i></a>&nbsp;&nbsp;
                        <a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a>&nbsp;&nbsp;
                    </span>
                </div>
            </div>
                <div className={navbar ? styles.header : styles.headerMinimized}>

                    <div className={styles.headerleft}>
                        <div className={styles.logoFrame}>
                            <img alt="logo" src={Logos} />

                            {/* <div className={styles.logoCaption}> */}
                            {/* <span className={styles.logoCaptionText1}>JOINT</span> */}
                            {/* <span className={styles.logoCaptionText2}>HEALTH CARE SERVICE INC.</span> */}
                            {/* </div> */}
                        </div>
                    </div>
                    <div className={styles.headermiddle}>
                        {/* <Square fa={<i class='fa fa-info' style={{fontSize:20}}></i>} title={"About Us"}/> */}
                        <Square fa={<i class='fa fa-circle ' style={{ fontSize: 20, color: "rgb(194, 185, 63)", opacity: 0.4 }}></i>} title={"Register"} onClick={ourService} />
                        <Square fa={<i class='fa fa-circle' style={{ fontSize: 20, color: "rgb(194, 185, 63)", opacity: 0.4 }}></i>} title={"Nominate"} onClick={ourAddress} />
                        <Square fa={<i class='fa fa-circle ' style={{ fontSize: 20, color: "rgb(194, 185, 63)", opacity: 0.4 }}></i>} title={"Vote"} onClick={ourAddress} />


                        <a href="tel:233302522909">  <button className={styles.headerButton}><i class='fa fa-phone' style={{ fontSize: 20 }}></i>&nbsp;Make Enqury</button></a>

                    </div>
                    <div className={styles.menuicon}>
                        <button onClick={handleClick}> <i className="fa fa-bars" aria-hidden="true"></i></button>
                        <div className={styles.dropdownmenu} style={{ display: display }}>
                            <div className={styles.dropdown}>
                                {/* <span><i class='fa fa-info' style={{fontSize:20}}></i>&nbsp;About Us</span> */}
                                <span ><i class="fa fa-circle " style={{ fontSize: 10 }} aria-hidden="true"></i>&nbsp;<a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/register">Register</a></span>
                                <span ><i class="fa fa-circle " style={{ fontSize: 10 }} aria-hidden="true"></i>&nbsp;<a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com">Login</a></span>
                                <span ><i class='fa fa-circle ' style={{ fontSize: 10 }}></i>&nbsp;<a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/register">Nominate</a></span>
                                <span><i class='fa fa-circle ' style={{ fontSize: 10 }}></i>&nbsp;<a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/register">Vote</a></span>




                            </div>
                        </div>
                    </div>

                </div>
            </>
            {/* <Headers ourService={ourService} ourAddress={ourAddress}/> */}
            <div className={styles.main}>
                <div className="carousel-wrapper" >
                    <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} stopOnHover={false} interval={7000} transitionTime={500}>
                        <div className={styles.carousel}>

                            <img alt="picture" src={nurse3} />
                            <div className={styles.overlay}>
                                <HeaderAnimation>
                                    <div className={styles.carouselContent}>
                                        <HeaderAnimation>
                                            <div className={styles.carouselText1}>5TH ANNUAL NURSING AND MIDWIFERY LEADERS AND MANAGERS CONFERENCE AND 4TH EXCELLENCE AWARDS</div>
                                        </HeaderAnimation>


                                        <Animation2>
                                            <div className={styles.carouselButton}>  
                                            <a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/register">
                                            <button>Register Now</button></a></div>
                                        </Animation2>
                                    </div>
                                </HeaderAnimation>
                            </div>
                        </div>
                        <div className={styles.carousel}>
                            <img alt="picture" src={nurse1} />
                            <div className={styles.overlay}>
                                <Animation2>
                                    <div className={styles.carouselContent}>
                                        <HeaderAnimation>
                                            <div className={styles.carouselText1}>5TH ANNUAL NURSING AND MIDWIFERY LEADERS AND MANAGERS CONFERENCE AND 4TH EXCELLENCE AWARDS</div>
                                        </HeaderAnimation>
                                        {/* <HeaderAnimation>
                                            <span className={styles.carouselText2}>Count on us to be the best. With<br />
                                                guaranteed 24/7 support services</span>
                                        </HeaderAnimation> */}

                                        <Animation2>
                                            <div className={styles.carouselButton}> 
                                            <a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/register">
                                            <button>Register Now!</button></a></div>
                                        </Animation2>
                                    </div>
                                </Animation2>
                            </div>
                        </div>
                        <div className={styles.carousel}>
                            <img alt="picture" src={nurse4} />
                            <div className={styles.overlay}>
                                <HeaderAnimation>
                                    <div className={styles.carouselContent}>
                                        <HeaderAnimation>
                                            <div className={styles.carouselText1}>5TH ANNUAL NURSING AND MIDWIFERY LEADERS AND MANAGERS CONFERENCE AND 4TH EXCELLENCE AWARDS</div>
                                        </HeaderAnimation>
                                        {/* <Animation2>
                                            <span className={styles.carouselText2}>Let us handle your Event's <br /></span>
                                              </Animation2> */}

                                        <div className={styles.carouselButton}> 
                                        <a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/register">
                               
                                                 <button>Register  Now!</button></a></div>

                                    </div>
                                </HeaderAnimation>
                            </div>
                        </div>
                    </Carousel>
                </div>

                <Row>
                    <div className={styles.rowContent}>
                        <div className={styles.rowContentLeft}>
                            <div className={styles.why_us_contents}>
                                <Parallaxword2
                                    icon={<span class='fa-stack '>
                                        <i className='fa fa-circle fa-stack-2x'></i>
                                        <i className='fa fa-diamond fa-spin fa-stack-1x fa-inverse'></i>
                                    </span>}
                                    wordtitle={""} >
                                    <div style={{
                                        color: "rgb(146, 139, 40)", fontWeight: 500, fontSize: 20,
                                        borderBottom: "1px solid rgb(199, 189, 50,0.5)", borderTop: "1px solid rgb(199, 189, 50,0.5)"
                                    }}>
                                        Empowering Nursing and Midwifery Leaders and Managers to Promote Quality Healthcare Delivery</div>
                                </Parallaxword2>
                                <Parallaxword2
                                    icon={<span class='fa-stack '>
                                        {/* <i className='fa fa-circle fa-stack-2x'></i> */}
                                        <i style={{ fontSize: 70 }} className='fa  fa-clock-o '></i>
                                    </span>}

                                    wordtitle={""} >
                                    <div style={{
                                        color: "rgb(146, 139, 30)", fontWeight: 500, fontSize: 25,
                                    }}>
                                        <Countdown date={new Date("September 26, 2024")} renderer={countdownRenderer} />

                                    </div>
                                </Parallaxword2>
                            </div>


                        </div>

                    </div>
                </Row>

                <Row>
                    <Parallaxrow >
                        <div ref={service} className={styles.rowContent2}>

                            <div className={styles.contentDivide}>

                                <div className={styles.ServiceRowContents}>
                                    <div className={styles.services}>
                                        {/* <div className={styles.serviceImageContainer}><img className={styles.serviceImage} src={Img2} alt="services"/></div> */}
                                        <div className={styles.fa_icons}>

                                            <a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/register">
                                                <button className={styles.actionButton}>
                                                    <img style={{ height: 50, color: "white" }} src={registerImg} alt="vote" />
                                                    Register
                                                </button>
                                            </a>
                                        </div>
                                        <div className={styles.serviceContent}>
                                            <div className={styles.serviceContentHeader}>

                                            </div>
                                            <div className={styles.serviceContentText}>
                                                <div></div>

                                                <span><strong>*</strong> Fee: GHS 3500</span>
                                            </div>

                                        </div>

                                    </div>


                                    <div className={styles.services}>
                                        <div className={styles.fa_icons}>
                                            <a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/nominate">
                                                <button className={styles.actionButton}>
                                                    <img style={{ height: 50, color: "white" }} src={nominateImg} alt="nominate" />
                                                    Nominate
                                                </button>
                                            </a>
                                            {/* <div className={styles.serviceImageContainer}><img className={styles.serviceImage} src={Img2} alt="services"/></div> */}

                                        </div>
                                        <div className={styles.serviceContent}>
                                            <div className={styles.serviceContentHeader}></div>
                                            <div className={styles.serviceContentText}>

                                                <div></div>


                                            </div>

                                        </div>

                                    </div>
                                    <div className={styles.services}>
                                        <div className={styles.fa_icons}>
                                            <a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com">
                                                <button className={styles.actionButton}>
                                                <img style={{ height: 50, color: "white" }} src={loginImg} alt="vote" />
                                                  
                                                    Login
                                                </button>
                                            </a>
                                            {/* <div className={styles.serviceImageContainer}><img className={styles.serviceImage} src={Img2} alt="services"/></div> */}

                                        </div>
                                        <div className={styles.serviceContent}>
                                            <div className={styles.serviceContentHeader}></div>
                                            <div className={styles.serviceContentText}>

                                                <div></div>


                                            </div>

                                        </div>

                                    </div>
                                    <div className={styles.services}>
                                        {/* <div className={styles.serviceImageContainer}><i className="fa fa-user-md"></i></div> */}

                                        <div className={styles.fa_icons}>
                                            <a style={{ textDecoration: "none" }} target="_blank" href="https://portal.mohannualnmcon.neutekmedia.com/vote">
                                                <button className={styles.actionButton}>
                                                    <img style={{ height: 50, color: "white" }} src={voteImg} alt="vote" />
                                                    Vote
                                                </button>
                                            </a>

                                        </div>
                                        <div className={styles.serviceContent}>
                                            <div className={styles.serviceContentHeader}></div>
                                            <div className={styles.serviceContentText}>
                                                <div></div>

                                                <span><strong>*</strong> USSD app (*713*897#)</span>
                                                {/* <span><strong>*</strong> Web app (www.ww.www)</span> */}
                                                <span><strong>*</strong> Fee: GHS1</span>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Parallaxrow >
                </Row>

                
                <Row>
                 
                <div className={styles.rowContent}>
              
                <div className={styles.rowContentLeft}>
                
                <Parallaxword
                                    // icon={<i class='fa fa-circle ' style={{ fontSize: 50, color: "rgb(194, 185, 63)", opacity: 0.8 }}></i>}
                                    wordtitle={"OUR SPONSORS"}

                                >

                                    
                                </Parallaxword>
                             
                <div className={styles.why_us_contents}>

                                <div className={styles.ServiceRowContents}>
                                    <div className={styles.services}>
                                          <img style={{ height: 140, color: "white" }} src={moh} alt="MOH" />
                                    </div>
                                    <div className={styles.services}>
                                          <img style={{ height: 150, color: "white" }} src={nursingmidwifery} alt="NMC" />
                                    </div>
                                    <div className={styles.services}>
                                          <img style={{ height: 150, color: "white" }} src={famanova} alt="NMC" />
                                    </div>
                                  
                                    <div className={styles.services}>
                                          <img style={{ height: 150, color: "white" }} src={jhpiego} alt="JHPIEGO" />
                                    </div>
                                </div>
                                
                            </div>
                            <div className={styles.why_us_contents}>

<div className={styles.ServiceRowContents}>
    <div className={styles.services}>
          <img style={{ height: 140, color: "white" }} src={hollardinsurance} alt="HOLLARDINSURANCE" />
    </div>
   
</div>

</div>
                        </div>
                        </div>
                 
                </Row>

                <Row>
                    <div className={styles.rowContent}>
                        <div className={styles.rowContentLeft}>
                            <div className={styles.why_us_contents}>
                                <Parallaxword3
                                    // icon={<i class='fa fa-circle ' style={{ fontSize: 50, color: "rgb(194, 185, 63)", opacity: 0.8 }}></i>}
                                    wordtitle={"GENERAL RULES AND CRITERIA FOR NOMINATION"}
                                >
                                    <div style={{
                                        color: "rgb(146, 139, 40)", fontWeight: 500, fontSize: 20,
                                    }}>

                                        <ol>
                                            <li>	Nominations start on 9st September and the deadline for submissions is 9th October, 2024</li>
                                            <li>	The nominee must fit the requirements of the category nominated for</li>
                                            <li>	You cannot nominate yourself</li>
                                            <li>	A nominee cannot be nominated for a category where he/she has previously received an award.</li>
                                            <li>	Previous winners can be nominated into a different category</li>
                                            <li>	Nominees can be in active service or retired.</li>
                                            <li>	You cannot nominate more than one person for one category. Double nominations for the same category will be rejected</li>
                                            <li>	Nominees must be informed before being nominated and indicate their acceptance of the nomination</li>
                                            <li>	Successful nominees will be communicated to and also made known publicly.</li>
                                            <li>	Supporting documents and evidence should be attached.</li>
                                            <li>	We have introduced a public voting system this year which will attract a small fee of One Ghana Cedis (GHS 1) per single vote using a short code applicable for all networks or through a web-based portal.</li>
                                            <li>	The public nomination and voting will start from 9th September to 15th October 2024.</li>
                                            <li>	Nominations will undergo a review by a panel of experts after the nomination and voting period.</li>
                                            <li>	Ten Highest Shortlisted nominees in line with all requurements will undergo interviews after which the final five except regulation category which is 3 will be selected to receive the award.</li>
                                            <li>	The pass mark for the interview to select the best five per category   will be  60% .</li>
                                            <li>	Winners will be invited to the 5th Leaders and Managers Conference to receive their awards.</li>

                                        </ol>
                                    </div>

                                </Parallaxword3>

                            </div>


                        </div>

                    </div>
                </Row>

                <Row>
                    <div className={styles.rowContent}>
                        <div className={styles.rowContentLeft}>
                            <div className={styles.why_us_contents}>
                                <Parallaxword3
                                    // icon={<i class='fa fa-circle ' style={{ fontSize: 50, color: "rgb(194, 185, 63)", opacity: 0.8 }}></i>}
                                    wordtitle={"CATEGORY 1: NURSING AND MIDWIFERY CLINICAL EXCELLENCE AWARD"}
                                >
                                    <div style={{
                                        color: "rgb(146, 139, 40)", fontWeight: 500, fontSize: 20,
                                    }}>
                                        <div style={{ fontSize: 15 }}> <small>(ONLY 5 AWARDS AVAILABLE)</small></div>
                                        <div style={{ paddingBottom: "10px", color: "grey", fontSize: 17 }}><i><small>CRITERIA</small></i></div>

                                        An individual registered NURSE/ MIDWIFE who;
                                        <ul>

                                            <li>	Provides exemplary direct patient care and contributes to the advancement of nursing practice by demonstrating a commitment to personal leadership development and serving as an inspirational role model for others</li>
                                            <li>	Contributed significantly to the advancement of professional nurses and midwives.</li>
                                            <li>	Improved the quality of direct patient care in his/her facility.</li>
                                            <li>	Recognized by peers for demonstrating professional behaviour in the provision of direct patient care.</li>
                                            <li>	Role model for compassion and exemplary practice.</li>
                                            <li>	Creates an environment that fosters care and compassion.</li>
                                            <li>	Creates an environment where attributes of trust, compassion, mutual respect, continued professional development and ethical behaviour are modelled and supported.</li>
                                            <li>	Is a mentor to staff.</li>
                                            <li>	Accessible, available and responsive to the needs of others, encouraging critical thinking and problem solving for individuals.</li>
                                            <li>	Promotes and enhances the image of nursing and midwifery within the organisation, community and the profession.</li>
                                            <li>	Demonstrates superior clinical nursing knowledge and expert skills for patient care.</li>
                                            <li>	Exhibits excellent customer service skills.</li>
                                            <li>	In good standing with the Nursing and Midwifery Council of Ghana.</li>



                                        </ul>
                                    </div>

                                </Parallaxword3>

                            </div>


                        </div>

                    </div>
                </Row>

                <Row>
                    <div className={styles.rowContent}>
                        <div className={styles.rowContentLeft}>
                            <div className={styles.why_us_contents}>
                                <Parallaxword3
                                    // icon={<i class='fa fa-circle ' style={{ fontSize: 50, color: "rgb(194, 185, 63)", opacity: 0.8 }}></i>}
                                    wordtitle={"CATEGORY 2: NURSING AND MIDWIFERY EDUCATION AND RESEARCH EXCELLENCE AWARD"}
                                >
                                    <div style={{
                                        color: "rgb(146, 139, 40)", fontWeight: 500, fontSize: 20,
                                    }}>
                                        <div style={{ fontSize: 15 }}> <small>(ONLY 5 AWARDS AVAILABLE)</small></div>
                                        <div style={{ paddingBottom: "10px", color: "grey", fontSize: 17 }}><i><small>CRITERIA</small></i></div>


                                        <ol>

                                            <li>	The nominee has made or is currently making contributions to the well-being and healthcare of patients through active participation in nursing and midwifery research.</li>
                                            <li>	Should have published at least two (2) papers in a recognized journal.</li>
                                            <li>	Involved in the process if conducting research independently or in collaboration with other disciplines</li>
                                            <li>	Models professionalism via excellence as a mentor, educator and/ or preceptor.</li>
                                            <li>	Demonstrates excellence through successful mentoring, education and/ or preceptor relationship with others.</li>
                                            <li>	Demonstrates a passion for excellence and exemplifies integrity and fairness.</li>
                                            <li>	Inspires other healthcare professionals by using an optimistic approach for change and encourages the building of relationships that will challenge the learners’ viewpoints.</li>
                                            <li>	Must be in good standing with the Nursing and Midwifery Council of Ghana.</li>
                                        </ol>
                                    </div>

                                </Parallaxword3>
                            </div>


                        </div>

                    </div>
                </Row>

                <Row>
                    <div className={styles.rowContent}>
                        <div className={styles.rowContentLeft}>
                            <div className={styles.why_us_contents}>
                                <Parallaxword3
                                    // icon={<i class='fa fa-circle ' style={{ fontSize: 50, color: "rgb(194, 185, 63)", opacity: 0.8 }}></i>}
                                    wordtitle={" CATEGORY 3: NURSING AND MIDWIFERY REGULATION AND ETHICS"}
                                >
                                    <div style={{
                                        color: "rgb(146, 139, 40)", fontWeight: 500, fontSize: 20,
                                    }}>
                                        <div style={{ fontSize: 15 }}> <small>(ONLY 3 AWARDS AVAILABLE)</small></div>
                                        <div style={{ paddingBottom: "10px", color: "grey", fontSize: 17 }}><i><small>CRITERIA</small></i></div>


                                        <ol>

                                            The nominee shall be someone who:

                                            <li>	Promotes public policy related to the safety and effective practice of nursing and midwifery in the interest of public welfare</li>
                                            <li>	Provides education, service and research through collaborative leadership to promote evidence-based regulatory excellence for patient safety and public protection.</li>
                                            <li>	Made an identifiable, significant contribution to the mission and vision of the Nursing and Midwifery Council consistently at all levels.</li>

                                            <li>	Has authentically demonstrated the promotion of the highest standards of nursing and midwifery ethics at national and international levels.</li>
                                            <li>	Must be in good standing with the Nursing and Midwifery Council of Ghana.</li>

                                        </ol>
                                    </div>

                                </Parallaxword3>
                            </div>


                        </div>

                    </div>
                </Row>

                <Row>
                    <div className={styles.rowContent}>
                        <div className={styles.rowContentLeft}>
                            <div className={styles.why_us_contents}>
                                <Parallaxword3
                                    // icon={<i class='fa fa-circle ' style={{ fontSize: 50, color: "rgb(194, 185, 63)", opacity: 0.8 }}></i>}
                                    wordtitle={" CATEGORY 4: NURSING AND MIDWIFERY LEADERSHIP AND GOVERNANCE"}
                                >
                                    <div style={{
                                        color: "rgb(146, 139, 40)", fontWeight: 500, fontSize: 20,
                                    }}>
                                        <div style={{ fontSize: 15 }}> <small>(ONLY 5 AWARDS AVAILABLE)</small></div>
                                        <div style={{ paddingBottom: "10px", color: "grey", fontSize: 17 }}><i><small>CRITERIA</small></i></div>


                                        <ol>
                                            The nominee must:
                                            <li>	Possess extraordinary passion, creativity and dedication to the Nursing and Midwifery profession</li>
                                            <li>	Be respected as a visionary, innovative leader and change agent</li>
                                            <li>	Move the profession forward through work in patient care, administration, education or research via professional activities, endeavours and/ or contributions</li>
                                            <li>	Demonstrate significant contributions fostering the goals of the Professional Nursing and Midwifery Practice Model.</li>
                                            <li>	Have a positive attitude even in challenging situation</li>
                                            <li>	Be a team player</li>
                                            <li>	Act professionally- dedicated, maintains high standards, adaptable and flexible</li>
                                            <li>	Be a role model – exhibits behaviours that others want to emulate, serves as an example for others and has a positive effect on all.</li>
                                            <li>	Be committed to learning – inquisitive with a thirst for knowledge and critical thinking</li>
                                            <li>	Have contributed to policy development at national and international levels.</li>
                                            <li>	Is currently occupying or has occupied a management position at national, regional, district or agency levels eg. Director, Deputy Director, CNMO, Registrar, Nurse Manager at a facility</li>
                                            <li>	Must be in good standing with the Nursing and Midwifery Council of Ghana</li>
                                        </ol>
                                    </div>

                                </Parallaxword3>

                            </div>


                        </div>

                    </div>
                </Row>

                <Row>
                    <div className={styles.rowContent}>
                        <div className={styles.rowContentLeft}>
                            <div className={styles.why_us_contents}>
                                <Parallaxword3
                                    // icon={<i class='fa fa-circle ' style={{ fontSize: 50, color: "rgb(194, 185, 63)", opacity: 0.8 }}></i>}
                                    wordtitle={"CATEGORY 5: DEPRIVED AREA AWARD: MINISTER FOR HEALTH AWARD"}
                                >
                                    <div style={{
                                        color: "rgb(146, 139, 40)", fontWeight: 500, fontSize: 20,
                                    }}>

                                        <ul>
                                            <li>	Rural or remote location with limited healthcare access</li>
                                            <li>Economically disadvantaged community</li>
                                            <li>Inadequate healthcare infrastructure and social amenities.</li>

                                        </ul>
                                        <div style={{ paddingBottom: "10px", color: "grey", fontSize: 17 }}><i><small>CRITERIA</small></i></div>

                                        <ol>
                                            <li>Registered nurses or midwives practicing in Ghana</li>
                                            <li>Minimum 2 years of service in a designated deprived area</li>
                                            <li>Demonstrated impact on community health outcomes</li>
                                            <li>Innovative solutions to resource constraints</li>
                                            <li>Leadership in improving local healthcare delivery</li>
                                            <li>Community engagement and health education efforts</li>
                                            <li>Detailed description of nominee's work and achievements</li>
                                            <li>Supporting evidence (e.g., statistics, testimonials)</li>
                                            <li>In good standing with the Nursing and Midwifery council of Ghana</li>
                                        </ol>
                                    </div>

                                </Parallaxword3>

                            </div>


                        </div>

                    </div>
                </Row>
                <Row>
                    <div className={styles.rowContent}>
                        <div className={styles.rowContentLeft}>
                            <div className={styles.why_us_contents}>
                                <Parallaxword3
                                    // icon={<i class='fa fa-circle ' style={{ fontSize: 50, color: "rgb(194, 185, 63)", opacity: 0.8 }}></i>}
                                    wordtitle={"AWARD PRIZES"}
                                >
                                    <div style={{
                                        color: "rgb(146, 139, 40)", fontWeight: 500, fontSize: 20,
                                    }}>


                                        <ol>
                                            <li> Customized Crystal Glass Plague</li>
                                            <li>Congratulatory Sash</li>
                                            <li>1 day opportunity to participate in the Leaders and Managers Conference</li>
                                            <li>1 night Accommodation to participate in the Awards Night with your partner/relative</li>
                                            <li>Any other additional consolation prices that may be introduced</li>
                                        </ol>
                                    </div>

                                </Parallaxword3>

                            </div>


                        </div>

                    </div>
                </Row>
                <Row>
                    <Parallaxrow >
                        <div ref={service} className={styles.rowContent2}>

                            <div className={styles.contentDivide}>

                                <div className={styles.ServiceRowContents}>
                                    <div className={styles.services}>
                                        {/* <div className={styles.serviceImageContainer}><img className={styles.serviceImage} src={Img2} alt="services"/></div> */}
                                        <div className={styles.fa_icons}>

                                            <button className={styles.actionButton}>
                                                <img style={{ height: 50, color: "white" }} src={registerImg} alt="vote" />
                                                Register
                                            </button>

                                        </div>
                                        <div className={styles.serviceContent}>
                                            <div className={styles.serviceContentHeader}>

                                            </div>
                                            <div className={styles.serviceContentText}>
                                                <div></div>

                                                <span><strong>*</strong> Fee: GHS 0000</span>
                                            </div>

                                        </div>

                                    </div>


                                    <div className={styles.services}>
                                        <div className={styles.fa_icons}>

                                            <button className={styles.actionButton}>
                                                <img style={{ height: 50, color: "white" }} src={nominateImg} alt="nominate" />
                                                Nominate
                                            </button>
                                            {/* <div className={styles.serviceImageContainer}><img className={styles.serviceImage} src={Img2} alt="services"/></div> */}

                                        </div>
                                        <div className={styles.serviceContent}>
                                            <div className={styles.serviceContentHeader}></div>
                                            <div className={styles.serviceContentText}>

                                                <div></div>


                                            </div>

                                        </div>

                                    </div>
                                    <div className={styles.services}>
                                        {/* <div className={styles.serviceImageContainer}><i className="fa fa-user-md"></i></div> */}

                                        <div className={styles.fa_icons}>
                                            <button className={styles.actionButton}>
                                                <img style={{ height: 50, color: "white" }} src={voteImg} alt="vote" />
                                                Vote
                                            </button>

                                        </div>
                                        <div className={styles.serviceContent}>
                                            <div className={styles.serviceContentHeader}></div>
                                            <div className={styles.serviceContentText}>
                                                <div></div>

                                                <span><strong>*</strong> USSD app (*713*xxx#)</span>
                                                <span><strong>*</strong> Web app (www.ww.www)</span>
                                                <span><strong>*</strong> Fee: GHS000</span>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Parallaxrow >
                </Row>



            </div>
            {/* <Footer/> */}

            <div ref={address} className={styles.footer} >
                <div className={styles.footerUpper}>
                    <div className={styles.logoFrame}>
                        <img alt="logo" src={Logos} />
                        {/* <img alt="logo" src={Logo} style={{width:140, height:140}}/> */}
                        {/* <div className={styles.logoCaption}> */}
                        {/* <span className={styles.logoCaptionText1}>JOINT</span> */}
                        {/* <span className={styles.logoCaptionText2} style={{color:'white'}}>HEALTH CARE SERVICE INC.</span> */}
                        {/* </div> */}
                    </div>
                    <div class={styles.socialmedia}>
                        <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>
                        <a href="#" class="twitter"><i class="fa fa-twitter"></i></a>
                        <a href="#" class="google"><i class="fa fa-instagram"></i></a>
                        <a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a>

                    </div>
                </div>

                <div className={styles.footerdescription}>
                    5TH ANNUAL NURSING AND MIDWIFERY LEADERS AND MANAGERS CONFERENCE AND 4TH EXCELLENCE AWARDS
                </div>

                <div className={styles.footerSub}>
                    <span>Copyright 2024 <span style={{ color: "rgb(218, 96, 9)" }}>
                        NMC CONFERENCE

                    </span> All Rights Reserved  </span>
                    <span><a>Privacy Policy</a></span>
                </div>

            </div>
        </div>
    )

}